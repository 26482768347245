import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UsersIndex = _resolveComponent("UsersIndex")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UsersIndex, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-8" })
  ]))
}