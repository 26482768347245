
<template>
  <div class="table-responsive">
    <table
      class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-150px">User</th>
          <th class="min-w-140px">Phone</th>
          <th class="min-w-120px">Email</th>
          <th class="min-w-100px ">Invited By</th>
          <th class="min-w-100px" v-if="$can('can_view_user_client_in_users_overview', 'all')">Client</th>
          <th class="min-w-140px text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in tableData" :key="user.id">
          <td>
            <div class="d-flex align-items-center">
              <div class="symbol symbol-45px me-5">
                <Img :src="user.photo_url" alt="" />
              </div>
              <div class="d-flex justify-content-start flex-column">
                <a
                  @click="$emit('viewUser', user.id)"
                  class="text-dark fw-bolder text-hover-primary fs-6"
                >
                  <span
                    v-if="user.status == 'inactive'"
                    class="badge badge-danger"
                    >Inactive</span
                  >
                  {{ user.name }}</a
                ><span
                  class="text-muted fw-bold text-muted d-block fs-7"
                  v-for="role in user.role"
                  :key="role.id"
                >
                  {{ role.title }}
                </span>
              </div>
            </div>
          </td>
          <td>
            <PhoneLink :phone="user.phone" css-classes="text-dark fw-bolder text-hover-primary d-block fs-6"></PhoneLink>
          </td>
          <td>
            <a
              :href="'mailto:' + user.email"
              class="text-dark fw-bolder text-hover-primary d-block fs-6"
              >{{ user.email }}</a
            >
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block fs-6"
              >{{ user.invited_by == null ? "" : user.invited_by.name }}</a
            >
          </td>
          <td v-if="$can('can_view_user_client_in_users_overview', 'all')">
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block fs-6"
              >{{ user.company == null ? "" : user.company.name }}</a
            >
          </td>
          <td class="text-end">
            <div class="btn-group btn-group-sm pb-2 pt-1" role="group">
              <a
                v-if="$can('user_impersonate', 'all')"
                @click="handleImpersonate(user.id)"
                type="button"
                class="btn btn-sm btn-warning"
                >Impersonate</a
              >
            </div>
            <br />
            <div class="btn-group btn-group-sm " role="group">
              <a
                v-if="$can('user_delete', 'all')"
                @click="deleteButtonClicked(user)"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
                class="btn btn-sm btn-danger"
                >Delete</a
              >
              <a
                v-if="$can('user_status_change', 'all')"
                @click.prevent="changeUserStatusButtonClicked(user)"
                type="button"
                :class="
                  `btn btn-${
                    user.status == 'active'
                      ? 'danger'
                      : 'success'
                  }`
                "
                data-bs-toggle="modal"
                data-bs-target="#ChangeUserStatusModal"
                >{{ user.status == "active" ? "Deactivate" : "Activate " }}</a
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Img from "@/components/general/Img.vue";
import PhoneLink from "@/components/general/PhoneLink.vue";
import ApiService from "@/core/services/ApiService";
import { mapGetters } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "UsersDataTable",
  components: {
    Img,
    PhoneLink
  },
  props: {
    tableData: Object
  },
  methods: {
    handleImpersonate(userId) {
      ApiService.post("api/v2/auth/impersonate/" + userId)
        .then(({ data }) => {
          this.$store.commit(Mutations.SET_AUTH, data);
        })
        .then(() => {
          location.reload(true);
        });
    },
    deleteButtonClicked(user) {
      this.$emit("deleteButtonClicked", user);
    },
    changeUserStatusButtonClicked(user) {
      this.$emit("changeUserStatusButtonClicked", user);
    }
  },
  computed: {
    ...mapGetters("UsersModule", [
      "usersList",
      "loading",
      "getPaginationData",
      "getSearchString"
    ])
  }
});
</script>
