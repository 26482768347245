<template>
  <div class="card">
    <ChangeUserStatusModal
        v-show="isThereAModalActive"
        :currentlySelectedUser="currentlySelectedUser"
        @changeUserStatus="handleChangeUserStatus"
    ></ChangeUserStatusModal>
    <DeleteModal
      v-show="isThereAModalActive"
      @delete="deleteSelectedUser"
      :currentlySelectedEntry="this.currentlySelectedUser.name"
    ></DeleteModal>
    <div class="card mb-5 mb-xl-8">
      <div class="card-header align-items-center border-0 pt-5">
        <h3 class="card-title flex-fill align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">Users Overview</span>

          <span class="text-muted mt-1 fw-bold fs-7"
            >Overview of all currently registered users</span
          >
        </h3>
        <div class="flex-fill position-relative pe-10">
          <IconSearch class="svg-icon-1 position-absolute ms-6" />
          <SearchField
            @changeSearch="updateSearchFilter"
            :placeholder="'Search Users'"
          ></SearchField>
        </div>
        <div
          class="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          v-if="$can('user_create', 'all')"
          title="Click to add a new user"
        >
          <router-link to="/users/create" class="btn btn-sm btn-light-primary"
            ><IconAddNew></IconAddNew>
            New User
          </router-link>
        </div>
      </div>
      <div class="mt-6 d-flex flex-column flex-sm-row w-100 w-md-75 px-8">
        <SkillsDropdown
            placeholder="Select skill"
            :class="`${parentSkill != '' && parentSkill != null ? 'me-0 me-sm-2 mb-2 mt-sm-0' : 'mb-2 mb-sm-0 me-0 me-sm-2 max-w-field'}`"
            v-model="parentSkill" @change="handleParentSkillSelect">
        </SkillsDropdown>
        <el-select
            v-model="subSkill"
            size="large"
            class="w-100 ms-0 ms-sm-2"
            v-if="parentSkill != '' && parentSkill != null"
            @change="handleChangeSubSkill"
            clearable
            placeholder="Select sub-skill"
        >
          <el-option
              v-for="item in getChildSkills"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="card-body py-3">
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <el-empty
          v-else-if="usersList.length == 0"
          :description="
            'OOPS... Unfortunately no users matching your filters were found.'
          "
        ></el-empty>
        <UsersDataTable
          v-else
          :tableData="usersList"
          @viewUser="handleViewUser"
          @deleteButtonClicked="openDeleteModal"
          @changeUserStatusButtonClicked="openChangeUserStatusModal"
        ></UsersDataTable>
      </div>
      <Pagination
        :currentPage="pagination.current_page"
        :totalPages="pagination.last_page"
        @changeCurrentPage="changeSearchByPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import UsersDataTable from "@/components/users/UsersDataTable.vue";
import SearchField from "@/components/general/SearchField.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";
import IconAddNew from "@/components/Icons/IconAddNew";
import SkillsDropdown from "@/components/dropdown/SkillsDropdown";
import ChangeUserStatusModal from "@/components/users/modal/ChangeUserStatusModal";
import IconSearch from "@/components/Icons/IconSearch";
import {generateBaseUrl} from "@/components/users/helpers";

export default defineComponent({
  name: "UsersIndex",
  data() {
    return {
      pagination: {},
      currentlySelectedUser: {
        id: null,
        name: null
      },
      subSkill: null,
      parentSkill: null,
      isThereAModalActive: false
    };
  },
  components: {
    UsersDataTable,
    IconAddNew,
    SkillsDropdown,
    IconSearch,
    SearchField,
    Pagination,
    LoadingSpinner,
    ChangeUserStatusModal,
    DeleteModal
  },
  methods: {
    ...mapActions("UsersModule", [
      "fetchData",
      "setSearchInput",
      "setPageNumber",
      "resetPageNumber",
      "resetState",
      "destroyData",
      "changeUserStatus",
      "setSubSkill",
      "setParentSkill"
    ]),
    fetchWithUpdatedFilter() {
      this.resetPageNumber();
      this.fetchData();
    },
    clearSubSkillState() {
      this.subSkill = null;
      this.setSubSkill("");
    },
    handleParentSkillSelect(event) {
      this.setParentSkill(event);
      if(event == "") {
        this.clearSubSkillState();
      }
      this.fetchWithUpdatedFilter()
    },
    handleChangeSubSkill(event) {
      this.setSubSkill(event);
      this.fetchWithUpdatedFilter()
    },
    updateSearchFilter(textInput) {
      if (this.$route.query.search !== textInput) {
        this.setSearchInput(textInput);
        this.fetchWithUpdatedFilter();
      }
    },
    clearQueryParams() {
      const query = Object.assign({}, this.$route.query);
      delete query.page;
      delete query.search;
      delete query.parentSkill
      delete query.subSkill
      this.$router.replace({ query });
    },
    changeSearchByPage(pageNumber) {
      this.setPageNumber(pageNumber);
      this.fetchData();
    },
    openDeleteModal(entry) {
      this.currentlySelectedUser = entry;
      this.isThereAModalActive = true;
    },
    deleteSelectedUser() {
      this.destroyData(this.currentlySelectedUser.id);
      this.isThereAModalActive = false;
      this.currentlySelectedUser.id = null;
    },
    openChangeUserStatusModal(user) {
      this.currentlySelectedUser = user;
      this.isThereAModalActive = true;
    },
    handleChangeUserStatus(user) {
      this.changeUserStatus({
        id: user.id,
        status: user.status == 'active' ? 'inactive' : 'active'
      });
    },
    handleViewUser(userId) {
      const url = generateBaseUrl(userId, this.getPaginationData.current_page, this.getSearchString, 'users') +
        `${this.getParentSkill !== "" ? `&parentSkill=${this.getParentSkill}` : '' }` +
        `${this.getSubSkill !== "" ? `&subSkill=${this.getSubSkill}` : '' }`;
      this.$router.push(url);
    },
    setSkillFromRouteParam(query) {
      if (query.parentSkill && !isNaN(query.parentSkill) && query.parentSkill.trim() !== "") {
        if (query.subSkill && query.subSkill.trim() !== "" && !isNaN(query.subSkill)) {
          this.subSkill = parseInt(query.subSkill);
          this.setSubSkill(query.subSkill);
        }
        this.parentSkill = parseInt(query.parentSkill);
        this.setParentSkill(query.parentSkill);
      }
    }
  },
  computed: {
    ...mapGetters("UsersModule", [
      "usersList",
      "loading",
      "getParentSkill",
      "getSubSkill",
      "getPaginationData",
      "getSearchString"
    ]),
    ...mapGetters("ListsModule", [
        "skills"
    ]),
    getChildSkills() {
      return this.parentSkill !== "" && this.parentSkill != null ? this.skills.find(skill => skill.id == this.parentSkill).children : null;
    }
  },
  mounted() {
    if (this.$route.query.page) this.setPageNumber(this.$route.query.page);
    if (this.$route.query.search) this.setSearchInput(this.$route.query.search);
    this.setSkillFromRouteParam(this.$route.query);
    this.fetchData();
    this.clearQueryParams();
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  unmounted() {
    this.resetState();
  }
});
</script>

<style>
.max-w-field{
  max-width: 50%;
}
@media screen and (max-width: 576px) {
  .max-w-field{
    max-width: 100%;
  }
}
</style>
