
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    currentlySelectedUser: Object
  },
  methods: {
    changeUserStatusButtonClicked() {
      this.$emit("change-user-status", this.currentlySelectedUser);
    }
  }
});
