
import { defineComponent, onMounted } from "vue";
import UsersIndex from "@/components/users/Index.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "UsersView",
  components: {
    UsersIndex
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Users");
    });
  }
});
